/* eslint-disable no-useless-escape */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import './bookNow.css';

// import Fade from 'react-reveal/Fade';
import * as emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import Form from './index.js';

const DiscussForm = (props)  => {
  const { data, resetForm } = props;

  const submitEmail = () => {
    const {
      name, company, email, phone, projectIdea,
    } = data;

    const templateParams = {
      from_name: `${name} - ${company} ( ${phone} - ${email} )`,
      to_name: 'Spotlight Captail Investors',
      message: projectIdea,
    };

    if (
      name !== ''
            && company !== ''
            && email !== ''
            && phone !== ''
            && projectIdea !== ''
    ) {
      emailjs.send(
        'service_qof0ese',
        'template_grelr6j',
        templateParams,
        '0xaTxnAKKqB-IatXV',
      )
        .then(() => {
          toast.success('Your issue has been submitted. The maintenance team will respond in 24 hours.');
          resetForm();
        }, (error) => {
          // toast.error(error);
        });
    } else {
      toast.error('Please fill out the blank form.');
    }
  };

 

  return (
    <section >
      {/* <Fade bottom> */}

        <h1 style={{display: 'flex', justifyContent: 'center'}}>Maintenance Form</h1>
        <h4 className="font-light text-lg text-gray-400 text-center mb-12">          
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Here at Callaway Estates, we aim to resolve all repairs and issues as quickly as possible.
        </h4>
        <h4 className="font-light text-lg text-gray-400 text-center mb-12">          
          {/* eslint-disable-next-line react/no-unescaped-entities */}
        Please submit a ticket outlining your issue. The maintenance team will respond as soon as possible.
        </h4>
        <h4 className="font-light text-lg text-gray-400 text-center mb-12">Email Directly: info@spotlightcapitalinvestors.com</h4>
      {/* <p className="font-light text-lg text-gray-400 text-center mb-12">cmurrill@spotlightcapitalinvestors.com </p>
      <p className="font-light text-lg text-gray-400 text-center mb-12"> rvizcarrondo@spotlightcapitalinvestors.com</p> */}
        <div >
          <div className="selectInputs">
            <input
              id="name"
              name="name"
              type="text"
              value={data.name}
              placeholder="Your name"
              className='inputText'
              onChange={props.onChange}
            />
                        <select id='company' name='company'  onChange={props.onChange}>
                            <option value='Air Conditioning/Heating'>Air Conditioning/Heating</option>
                            <option value='Plumbing'>Plumbing</option>
                            <option value='Electrical'>Electrical</option>
                            <option value='Kitchen Appliances'>Kitchen Appliances</option>
                            <option value='Water Heater'>Water Heater</option>
                            <option value='External'>External</option>
                            <option value='Other'>Other</option>

                        </select>
          </div>

          <div className="selectInputs">
            <input
              id="email"
              name="email"
              type="email"
              value={data.email}
              placeholder="Your email address"
              className='inputText'
              onChange={props.onChange}
            />

            <input
              id="phone"
              name="phone"
              type="tel"
              value={data.phone}
              placeholder="Your contact number"
              className='inputText'
              onChange={props.onChange}
            />
          </div>

          <div style={{display: 'flex', justifyContent: 'center'}} className="mx-auto">
            <input
              id="projectIdea"
              name="projectIdea"
              type="textarea"
              width="200px"
              value={data.projectIdea}
              placeholder="Message"
              className='inputText'
              onChange={props.onChange}
            />
          </div>

<div style={{display: 'flex', justifyContent: 'center'}}>
          <button
           
            className="btnStyle btnActive"
            type="button"
            onClick={submitEmail}
          >
            SUBMIT
          </button>
          </div>
        </div>
      {/* </Fade> */}

      <ToastContainer />

    </section>
  );
}

export default DiscussForm;