import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD8I5-PaHZC5Cl_93NLM6Aq8sbZqgi0E3s",

  authDomain: "spotlightwebsite-dc8fc.firebaseapp.com",
  //"spotlightcapitalinvestors.netlify.app/",

  projectId: "spotlightwebsite-dc8fc",

  storageBucket: "spotlightwebsite-dc8fc.appspot.com",

  messagingSenderId: "627101730756",

  appId: "1:627101730756:web:7b6ef664f54ca082e40691",

  measurementId: "G-ZLV9SYPJQK",

  databaseURL: "https://spotlightwebsite-dc8fc-default-rtdb.firebaseio.com/",
}

firebase.initializeApp(firebaseConfig);


export const auth = firebase.auth();
export const firestore = firebase.firestore();
