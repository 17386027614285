import Navbar from './Navbar';
import Create from './Create';
import Payment from './Payment';
import Maintanence from './DiscussProjectPage'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NotFound from './NotFound';
import SignIn from './SignIn';
import BlogList from './BlogList';
import SuccessPage from './SuccessPage';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import VideoCarousel from './VideoCarousel';

import Completion from './Completion'

import {useEffect, useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';

const auth = firebase.auth();


const App = () => {
  const [user] = useAuthState(auth);
  const [ stripePromise, setStripePromise ] = useState('null');

  useEffect(() => {
    fetch("/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  return (
    <Router>
      <div className="App">
        <Navbar />

        {/* <div className="header"> */}
                <VideoCarousel/>

            {/* <p >Welcome To Spotlight Capital Investors</p>
            <p className="heading-sm"></p> */}
          {/* </div> */}
          
        <div>
        {user ?
            <Switch>
              <Route path='/' exact component={BlogList}
              />
              <Route path='/create' exact component={Create} />
              <Route path='/payment'  component={Payment} />
              <Route path="/completion" element={<Completion stripePromise={stripePromise} />} />
              <Route path='/maintenance' exact component={Maintanence} />
              <Route path='/success' exact component={SuccessPage} />

              <Route path='*' component={NotFound} />
            </Switch>
          : <SignIn />}
        </div>
        <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3089.497420880035!2d-76.6773960846476!3d39.34559827950056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c80758417bba41%3A0x340db5d670e9c0ec!2s3829%20Callaway%20Ave%2C%20Baltimore%2C%20MD%2021215%2C%20USA!5e0!3m2!1sen!2s!4v1589289377896!5m2!1sen!2s"         height={400}
         width='100%' >

        </iframe>
      </div>
    </Router>
  );
}

export default App;
