import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';
import React from 'react';
import { useEffect, useState } from 'react';
import UsBankAccountDebitForm from './UsBankAccountDebitForm'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import CheckoutForm from './CheckoutForm'
//chimeres token
const stripePromise = loadStripe('pk_live_51LW59wFBwaRj3QZeIjUfce7x7uTDGc4HMmrX9K2TieG1thxxlqpyODXSbkk532rKoQ16TvobsnPXHMmm7J3DujBx00Zwcjk696')
//("pk_test_51LW59wFBwaRj3QZeJS9rNSeuXql521WNTZcS1diK0mX0QcsVV8rFpwj7ab7n8FiRRqJOJDVbNMrzNN5zOK8aCmgR00XhEQPArX");

function Payment() {

  const auth = firebase.auth();

  const [clientSecret, setClientSecret] = useState('');
  const [user] = useAuthState(auth);
  const username = user.displayName
  const [payAmount, setpayAmount] = useState(0);

  const calculateOrderAmount = () => {
  // Replace this constant with a calculation of the order's amount
  // Calculate the order total on the server to prevent
  // people from directly manipulating the amount on the client
  return payAmount * 100;
};

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch("/create-payment-intent", {
    // try {
    // Get the Payment Intent
    console.log(`Retrieving the payment intent.`)
    if (user.email == "liyahagar@gmail.com") {
      
      fetch("/.netlify/functions/server", {
        method: "POST",
  
        body: JSON.stringify({
          amount: 125000, //170000 //125000 / payAmount * 100
          currency: "usd",
          automatic_payment_methods: { enabled: true },
        }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));

    } else if (user.email == "robinsondarrow@gmail.com") {
      
      fetch("/.netlify/functions/server", {
        method: "POST",
  
        body: JSON.stringify({
          amount: 170000, //170000 //125000 / payAmount * 100
          currency: "usd",
          automatic_payment_methods: { enabled: true },
        }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));

    } else if (user.email == "clmurri@gmail.com") {
      
      fetch("/.netlify/functions/server", {
        method: "POST",
  
        body: JSON.stringify({
          amount: 125000, //170000 //125000 / payAmount * 100
          currency: "usd",
          automatic_payment_methods: { enabled: true },
        }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));

    } else if (user.email == "jimetradepree@gmail.com") {
      
      fetch("/.netlify/functions/server", {
        method: "POST",
        body: JSON.stringify({
          amount: 120000, //170000 //125000 / payAmount * 100
          currency: "usd",
          automatic_payment_methods: { enabled: true },
        }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
    } 
    //Jimetradepree@gmail.com

      // .then( (session) => stripe.redirectToCheckout({ sessionId: session.id }))
     
    //, console.log(`clientSecret: ${clientSecret}`)
    //   const { paymentIntent } =  intent.json()
    //   console.log(`Successfully retrieved the payment intent.`)

    //   // Confirm the Card Payment
    //   console.log(`Confirming Card Payment`)
    //    stripe.confirmCardPayment(paymentIntent.client_secret, {
    //     payment_method: {
    //       card: elements.getElement(CardElement),
    //       billing_details: {
    //         email: profile.email,
    //       },
    //     },
    //   })
    //   console.log(`Card Payment Successful`)
    // } catch (err) {
    //   console.log(err)
    // }
    // fetch("/.netlify/functions/server",{
    //   method: "POST",
    //   // headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     amount: 4800,
    //     currency: "usd",
    //   }),
    //   // body: JSON.stringify({ items: [{ id: "rent" }] }),
    // })

  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };


  return (
    <>
      {/* <UsBankAccountDebitForm/> */}
      <div className='apptContainer' style={{}}>
        <div style={{ justifyContent: 'center' }} >
          <h1 className='apptTitle' style={{ paddingTop: '60px', paddingBottom: '10px' }}>Make a Payment</h1>
          <h3 className='apptTitle' style={{ paddingTop: '10px', paddingBottom: '10px' }}>Selecting “Pay Now”, will charge your bank the Full Rental Amount.</h3>

         {/* <TextField
          
          id="outlined-basic"
          variant="outlined"
          inputProps={{style: {fontSize: 20}}} // font size of input text

           style={{paddingTop: '10px', paddingBottom: '10px' }}
            value={payAmount}
            label="Enter rent amount (DO NOT ENTER COMMAS)"
            onChange={(e) => {
              setpayAmount(e.target.value);
            }}
          />
          <h3>Your bank account will be charged: {payAmount} </h3> */}
        </div>

        {clientSecret && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        )}

        {/* {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret, }}>
          <CheckoutForm />
        </Elements>
      )} */}
        {/* <div className='embedContainer'>
                <embed width='100%' height='100%' src=""></embed>
            </div> */}
      </div>
    </>
  )
}

export default Payment;