/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';

import DiscussForm from './DiscussForm';

export default class DiscussProjectPage extends Component {

// const DiscussProjectPage = () => {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: '',
        company: 'Air Conditioning/Heating',
        email: '',
        phone: '',
        projectIdea: '',
      },
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

    onChange = (event) => {
      this.setState({
        data: {
          // eslint-disable-next-line react/no-access-state-in-setstate
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      });
    }

    resetForm = () => {
      this.setState({
        data: {
          name: '',
          company: 'Air Conditioning/Heating',
          email: '',
          phone: '',
          projectIdea: '',
        },
      });
    }

    render() {
      const { data } = this.state;

      return (
         <div style={{paddingBottom: 30, backgroundColor: 'white'}}>
          <DiscussForm data={data} onChange={this.onChange} resetForm={this.resetForm} />

         </div>
         
      );
    // }
}
}

// export default DiscussProjectPage;