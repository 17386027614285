import "./VideoCarousel.css";
import React from "react";
import { Carousel } from "react-bootstrap";
import Vid1 from "./video/walkvideo.mp4";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.min.css";
import fitty from "fitty";

const VideoCarousel = () => {
  const videoProperties = [
    {
      id: 1,
      title: "Video 1",
      src: Vid1,
    }
    // {
    //   id: 2,
    //   title: "Video 2",
    //   src: Vid2,
    // }
  ];

  return (
    <div className="App" style={{ position: 'relative' }}>
      <Carousel className="carousel" >
        {videoProperties.map((videoObj) => {
          return (
            
            <Carousel.Item key={videoObj.id}>
              <ReactPlayer
              type="video/mp4"
                width={"100%"}
                height={"50%"}
                url={videoObj.src}
                pip={true}
                controls={false}
                playing={true}
                autoPlay={true}
                loop={true}
                muted={true}
                playsinline={true}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
fitty('.fit', {
  multiLine: true,
  minSize: 8
});
export default VideoCarousel;
