import React from "react";

const SuccessPage = () => {

    return (
        <div className='apptContainer' >
        <div style={{ justifyContent: 'center' , paddingBottom:90}} >
            <h1 >PAYMENT WAS SUCCESSFUL! THANK YOU! </h1>

            </div>
        </div>
    );
}

export default SuccessPage;