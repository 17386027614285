import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase/app';

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const auth = firebase.auth();

  const [user] = useAuthState(auth);
  const username = user.displayName

  // const handleSubmit1 = async event => {
  //   event.preventDefault()

  //   // Ultimately grab this from the form
  //   const profile = {
  //     email: "johnny@lovelife.com",
  //   }

  //   // PROCEED WITH CARD PROCESSING
  //   try {
  //     // Get the Payment Intent
  //     console.log(`Retrieving the payment intent.`)
  //     const intent = await fetch("/.netlify/functions/server", {
  //       method: "POST",
  //       body: JSON.stringify({
  //         amount: 4800,
  //         currency: "usd",
  //       }),
  //     })
  //     const { paymentIntent } = await intent.json()
  //     console.log(`Successfully retrieved the payment intent.`)

  //     // Confirm the Card Payment
  //     console.log(`Confirming Card Payment`)
  //     await stripe.confirmCardPayment(paymentIntent.client_secret, {
  //       payment_method: {
  //         card: elements.getElement(CardElement),
  //         billing_details: {
  //           email: profile.email,
  //         },
  //       },
  //     })
  //     console.log(`Card Payment Successful`)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://spotlightcapitalinvestors.netlify.app/success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }
    try {
    const intent =  await fetch("/.netlify/functions/server", {
      method: "POST",
      body: JSON.stringify({
       // amount: 170000,
        currency: "usd",
      }),
    })

    const { paymentIntent } =  await intent.json()
    await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        billing_details: {
         name: username,
        },
      },
    })
  } catch (err) {
    console.log(err)
  }
    setIsLoading(false);
  };

  return (
  //   <form onSubmit={handleSubmit}>
  //   <CardElement />
  //   <button type="submit" disabled={!stripe}>
  //     Pay
  //   </button>
  // </form>
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

// import {
//     PaymentElement
//   } from '@stripe/react-stripe-js'
//   import {useState} from 'react'
//   import {useStripe, useElements} from '@stripe/react-stripe-js';
  
//   export default function CheckoutForm() {
//     const stripe = useStripe();
//     const elements = useElements();
//     const [message, setMessage] = useState(null);
//     const [isLoading, setIsLoading] = useState(false);
  
//     const handleSubmit = async (e) => {
//       e.preventDefault();
  
//       if (!stripe || !elements) {
//         // Stripe.js has not yet loaded.
//         // Make sure to disable form submission until Stripe.js has loaded.
//         return;
//       }
  
//       setIsLoading(true);
  
//       const { error } = await stripe.confirmPayment({
//         elements,
//         confirmParams: {
//           // Make sure to change this to your payment completion page
//           return_url: `${window.location.origin}/completion`,
//         },
//       });
  
//       // This point will only be reached if there is an immediate error when
//       // confirming the payment. Otherwise, your customer will be redirected to
//       // your `return_url`. For some payment methods like iDEAL, your customer will
//       // be redirected to an intermediate site first to authorize the payment, then
//       // redirected to the `return_url`.
//       if (error.type === "card_error" || error.type === "validation_error") {
//         setMessage(error.message);
//       } else {
//         setMessage("An unexpected error occured.");
//       }
  
//       setIsLoading(false);
//     }
  
//     return (
//       <form id="payment-form" onSubmit={handleSubmit}>
//         <PaymentElement id="payment-element" />
//         <button disabled={isLoading || !stripe || !elements} id="submit">
//           <span id="button-text">
//             {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
//           </span>
//         </button>
//         {/* Show any error or success messages */}
//         {message && <div id="payment-message">{message}</div>}
//       </form>
//     )
//   }