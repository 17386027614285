import { Link } from 'react-router-dom';
import SignOut from './SignOut';
import { auth } from './firebase.config'
import React from 'react';
import logo from './images/spotlight_logo.png'

const Navbar = () => {
    return (
        <>
        <nav className="navbarTop" media="only screen and (max-device-width: 480px)" style={{ justifyContent: 'center'}} height={20} >
            <h2 style={{  color:'white'}}>
            Not just properties, we're a rental experience!
            </h2>
        </nav>
        <nav className="navbar">
            <Link to="/">
                {/* <h4>Home</h4> */}
            
            <img alt="logo" src={logo} width={190} style={{paddingLeft:"3vh"}} />
                </Link>
            <div className="links">
                {auth.currentUser && <>
                <Link to="/create"><button className="add">New Post</button> </Link>
                <Link to="/payment"> <button className="add">Payment</button></Link>
                <Link to="/maintenance"> <button className="add">Maintenance</button></Link></>
               }
                <SignOut />
            </div>
        </nav>
        </>
    );
}

export default Navbar;